import React, { useState, useContext } from "react"
import * as qs from "query-string"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"
import { Helmet } from "react-helmet-async"

import jiggswapApi from "../api/jiggswap"

import { AuthContext } from "../context/AuthContext"
import { MapApiErrors } from "../helpers/ErrorParser"
import JiggswapInput from "../components/forms/JiggswapInput"

const ResetPasswordPage = ({ location }) => {
  const { login } = useContext(AuthContext)

  const { register, handleSubmit, errors, setError } = useForm()
  const query = qs.parse(location.search)
  const [submitState, setSubmitState] = useState("ready")

  const isReady = submitState === "ready"
  const isSubmitting = submitState === "submitting"
  const isSubmitted = submitState === "submitted"

  const submitForm = async ({ password, passwordConfirmation, email }) => {
    setSubmitState("submitting")

    try {
      const response = await jiggswapApi.post("/auth/reset-password", { token: query.key, password, passwordConfirmation, email })

      login(response.data)

      setSubmitState("submitted")

      if (typeof window != "undefined") {
        navigate("/")
      }
    } catch (err) {
      try {
        let apiErrors = MapApiErrors(err.response.data.errors)
        setError(apiErrors)
      } catch (err2) {}

      setSubmitState("ready")
    }
  }

  return (
    <>
      <Helmet title="Reset Password - JiggSwap" />
      <div className="site-content">
        {isSubmitting && (
          <>
            <div className="is-size-2">Resetting Password</div>
            <div className="is-size-4">Updating your password...</div>
          </>
        )}
        {isSubmitted && (
          <>
            <div className="is-size-2">Password Reset!</div>
            <div className="is-size-4">You have been logged in & will be redirected to the Jiggswap Dashboard.</div>
          </>
        )}

        <form style={isReady ? undefined : { display: "none" }} onSubmit={handleSubmit(submitForm)}>
          <div className="is-size-2">Reset Password</div>
          <div className="is-size-4">Confirm your email and enter a new password below.</div>

          <JiggswapInput
            name="email"
            label="Email"
            helptext="Please use the email address where you received the reset instructions."
            type="email"
            register={register}
            errors={errors}
          />

          <JiggswapInput name="password" label="New Password" type="password" register={register} errors={errors} />

          <button className="button is-primary" type="submit">
            Submit
          </button>
        </form>
      </div>
    </>
  )
}

export default ResetPasswordPage
